import GatsbyImage from "gatsby-image";
import { truncate } from 'lodash';
import PropTypes from "prop-types";
import React from "react";
import MarkDownComponent from '../../components/markDown';
import { getNestedProp } from "../../helper/code";
import Link from "../../modules/link";

const SearchCard = ({ title, fullSlug, localizedPage, i }) => {
  const image = localizedPage?.seo?.searchResultImage || localizedPage?.seo?.ogImage 
  const description = localizedPage?.seo?.description
  const url = `/${fullSlug}`

  return (
    <Link to={url} className={"search-card-item text-decoration-none"}>
      <div className={`search-card-image ${!image ? 'no-image card-bg-' + (Math.floor(i % 9) + 1) : ''}`}>
        {image && (
          image?.fixed ? (
          <GatsbyImage fixed={image.fixed} alt={image?.description || image?.title}></GatsbyImage>
          ) : (<img src={image?.file?.url} alt={image?.description || image?.title}></img>)                 
        )}
      </div>
      <div className="search-card-text">
          <h5 className="mb-3">{localizedPage?.seo?.title || title}</h5>
          <div className="mb-3">
              <MarkDownComponent>
                {truncate(getNestedProp(description), {
                  'length': 120,
                  'omission': '...'
                })}
              </MarkDownComponent>
          </div>
      </div>
    </Link>
  )
}

SearchCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  node_locale: PropTypes.string,
  localizedPage: PropTypes.object,
}
SearchCard.defaultProps = {
  id: "",
  title: "",
  content: "",
  node_locale: "",
  localizedPage: {},
}

export default SearchCard
